import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

const DynamicList = ({ onItemsChange, itemsList }) => {
  const { control, register, handleSubmit, watch } = useForm({
    defaultValues: {
      items: itemsList && itemsList.length ? itemsList : [{ titulo: '', descripcion: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  // Emit items to parent whenever the form changes
  const items = watch('items');
  React.useEffect(() => {
    onItemsChange(items);
  }, [items, onItemsChange]);

  return (
    <Box sx={{mb: 2}}>
      <Typography variant="h5" gutterBottom>
        Listado de items
      </Typography>
      {fields.map((field, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={field.id}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={5}>
            <TextField
              {...register(`items.${index}.titulo`)}
              label="Título"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              {...register(`items.${index}.descripcion`)}
              label="Descripción"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => remove(index)} color="error">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => append({ titulo: '', descripcion: '' })}
      >
        Agregar item
      </Button>
    </Box>
  );
};

export default DynamicList;
